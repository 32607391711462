import { useEffect, useState } from 'react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './store/auth/authConfig';

import './assets/styles/main.css';

import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import Home from './containers/Home';
import Login from './containers/Login';
import Account from './containers/Account';

//Driver
import CompletedRaces from './containers/Driver/Races/CompletedRaces/CompletedRaces';
import ProjectRaces from './containers/Driver/Races/ProjectRaces/ProjectRaces';
import UnreconciledRaces from './containers/Driver/Races/UnreconciledRaces/UnreconciledRaces';
import EstimatedRaces from './containers/Driver/Economy/EstimatedRaces/EstimatedRaces';
import SavingsCommissions from './containers/Driver/Economy/SavingsCommissions/SavingsCommissions';
import SavingsMemoryCalculation from './containers/Driver/Economy/SavingsMemoryCalculation/SavingsMemoryCalculation';
import SavingsPerRace from './containers/Driver/Economy/SavingsPerRace/SavingsPerRace';
import NonConformity from './containers/Driver/Policies/NonConformity/NonConformity';
import ManagementAlerts from './containers/Driver/Policies/ManagementAlerts/ManagementAlerts';

//Adm
import ActiveUsers from './containers/Adm/GeneralSettings/ActiveUsers';
import ActiveUsersForm from './components/forms/Adm/ActiveUsers/ActiveUsersForm';
import InviteUsers from './containers/Adm/GeneralSettings/InviteUsers';
import InviteUsersForm from './components/forms/Adm/InviteUsersForm';
import InactiveUsers from './containers/Adm/GeneralSettings/InactiveUsers';
import ProjectsOs from './containers/Adm/GeneralSettings/ProjectsOs';
import ProjectOsForm from './components/forms/Adm/ProjectOsForm';
import Reason from './containers/Adm/GeneralSettings/Reason';
import ReasonForm from './components/forms/Adm/ReasonForm';
import BatchRegistration from './containers/Adm/GeneralSettings/BatchRegistration';
import IntegrationLog from './containers/Adm/GeneralSettings/IntegrationLog';
import PolicyPreferences from './containers/Adm/GeneralSettings/PolicyPreferences';
import Policies from './components/forms/Adm/Policy/Policies';
import PolicyUsers from './components/forms/Adm/Policy/PolicyUsers/PolicyUsers';
import PoliciesForm from './components/forms/Adm/Policy/PoliciesForm';
import Currency from './containers/Adm/KmSettings/Currency';
import ExpenseType from './containers/Adm/KmSettings/ExpenseType';
import ApprovalFlow from './containers/Adm/KmSettings/ApprovalFlow';
import ApprovalFlowForm from './components/forms/Km/ApprovalFlowForm';
import ActiveUsersKm from './containers/Adm/KmSettings/ActiveUsersKm';
import CompanyParameters from './containers/Adm/KmSettings/CompanyParameters';
import PendingAccountability from './containers/Adm/Rh/PendingAccountability';

// Expen
import Reports from './containers/Expen/Expen/Reports';
import ExpenseReport from './containers/Expen/Expen/ExpenseReport';
import KmReport from './containers/Expen/Expen/KmReport';
import Advances from './containers/Expen/Expen/Advances';
import Approval from './containers/Expen/ApprovalExpen/Approval';
import FinancialManagement from './containers/Expen/ApprovalExpen/FinancialManagement';
import Expenses from './containers/Expen/Expen/Expenses';
import ExpensesForm from './components/forms/Expen/ExpensesForm';
import ExpensesKmForm from './components/forms/Expen/ExpensesKmForm';

//Dashboard
import Volume from './containers/Dashboard/Driver/Volume';
import Gastos from './containers/Dashboard/Driver/Gastos';

// Wrapper
import { PageContainer } from './components/wrappers/PageContainer';
import { PageContainerDashboard } from './components/wrappers/PageContainer';

// Store
import { useDispatch, useSelector } from 'react-redux';
import { loginUserSso, USER_LOGOUT } from './store/_Entities/User';

// Hooks
import useAutoLogout from './hooks/useAutoLogout';

// MUI
import { Alert, Snackbar } from '@mui/material';
import Evaluate from './containers/Evaluate/Evaluate';
import ConciliateRace from './containers/Driver/Races/UnreconciledRaces/ConcialteRace';
import { MsalProvider } from '@azure/msal-react';

const MAX_IDLE_TIME_MS = 2.4e6; // 40 minutes
const IDLE_WARNING_TIME_MS = 60000; // 1 minute

function App({ instance }) {
  const { user } = useSelector((state) => state.entities);
  const ssoActiveUSer = instance.getActiveAccount();

  const idleTimer = useAutoLogout(MAX_IDLE_TIME_MS);

  const [openIdleTimeWarningSnackbar, setOpenIdleTimeWarningSnackbar] =
    useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleSignInEvent = (event) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        event.payload.account
      ) {
        const account = event.payload.account;
        dispatch(loginUserSso(account.username, account.localAccountId));
        instance.setActiveAccount(account);
      }
    };

    instance.addEventCallback(handleSignInEvent);

    return () => {
      instance.removeEventCallback(handleSignInEvent);
    };
  }, [instance, dispatch]);

  const autoLogoutUser = () => {
    dispatch(USER_LOGOUT());
    if (ssoActiveUSer) {
      instance.setActiveAccount(null);
      instance.clearCache();
      sessionStorage.clear();
    }
    navigate('/login');
    setOpenIdleTimeWarningSnackbar(false);
  };

  useEffect(() => {
    const date1 = new Date(user.loginDate);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    if (diffTime > MAX_IDLE_TIME_MS) autoLogoutUser();
    if (idleTimer <= IDLE_WARNING_TIME_MS) setOpenIdleTimeWarningSnackbar(true);
    if (idleTimer === MAX_IDLE_TIME_MS) setOpenIdleTimeWarningSnackbar(false);
    if (idleTimer === 0) autoLogoutUser();
  }, [idleTimer]);

  return (
    <MsalProvider instance={instance}>
      <Routes>
        <Route
          path="/"
          element={
            user.isLoggedIn === false ? (
              <Navigate to="/login" />
            ) : (
              <Navigate to="/home" />
            )
          }
        />

        <Route path="/login" element={<Login />} />

        <Route
          path="/home"
          element={
            <PageContainer protected>
              <Home />
            </PageContainer>
          }
        />

        <Route
          path="/account"
          element={
            <PageContainer protected>
              <Account />
            </PageContainer>
          }
        />

        <Route
          path="/driver/corridas-realizadas"
          element={
            <PageContainer protected>
              <CompletedRaces />
            </PageContainer>
          }
        />

        <Route
          path="/driver/corridas-por-projetos"
          element={
            <PageContainer protected>
              <ProjectRaces />
            </PageContainer>
          }
        />

        <Route
          path="/driver/corridas-nao-reconciliadas"
          element={
            <PageContainer protected>
              <UnreconciledRaces />
            </PageContainer>
          }
        />

        <Route
          path="/economy/corridas-estimadas"
          element={
            <PageContainer protected>
              <EstimatedRaces />
            </PageContainer>
          }
        />
        <Route
          path="/economy/economias-calculo"
          element={
            <PageContainer protected>
              <SavingsMemoryCalculation />
            </PageContainer>
          }
        />
        <Route
          path="/economy/economias-comissionadas"
          element={
            <PageContainer protected>
              <SavingsCommissions />
            </PageContainer>
          }
        />
        <Route
          path="/economy/economias-por-corrida"
          element={
            <PageContainer protected>
              <SavingsPerRace />
            </PageContainer>
          }
        />
        <Route
          path="/policies/nao-conformidade"
          element={
            <PageContainer protected>
              <NonConformity />
            </PageContainer>
          }
        />
        <Route
          path="/policies/alertas-gerenciais"
          element={
            <PageContainer protected>
              <ManagementAlerts />
            </PageContainer>
          }
        />
        <Route
          path="/adm/usuarios-convidados"
          element={
            <PageContainer protected>
              <InviteUsers />
            </PageContainer>
          }
        />
        <Route
          path="/adm/usuarios-convidados/add/:companyID"
          element={
            <PageContainer protected>
              <InviteUsersForm />
            </PageContainer>
          }
        />
        <Route
          path="/adm/usuarios-ativos"
          element={
            <PageContainer protected>
              <ActiveUsers />
            </PageContainer>
          }
        />
        <Route
          path="/adm/usuarios-ativos/edit/:companyID/:userID"
          element={
            <PageContainer protected>
              <ActiveUsersForm />
            </PageContainer>
          }
        />
        <Route
          path="/adm/usuarios-inativos"
          element={
            <PageContainer protected>
              <InactiveUsers />
            </PageContainer>
          }
        />
        <Route
          path="/adm/projetos-os"
          element={
            <PageContainer protected>
              <ProjectsOs />
            </PageContainer>
          }
        />
        <Route
          path="/adm/projetos-os/add/:companyID"
          element={
            <PageContainer protected>
              <ProjectOsForm />
            </PageContainer>
          }
        />
        <Route
          path="/adm/projetos-os/edit/:companyID/:projectID"
          element={
            <PageContainer protected>
              <ProjectOsForm />
            </PageContainer>
          }
        />
        <Route
          path="/adm/motivo"
          element={
            <PageContainer protected>
              <Reason />
            </PageContainer>
          }
        />
        <Route
          path="/adm/motivo/add"
          element={
            <PageContainer protected>
              <ReasonForm />
            </PageContainer>
          }
        />
        <Route
          path="/adm/motivo/:reasonID"
          element={
            <PageContainer protected>
              <ReasonForm />
            </PageContainer>
          }
        />
        <Route
          path="/adm/cadastro-em-lote"
          element={
            <PageContainer protected>
              <BatchRegistration />
            </PageContainer>
          }
        />
        <Route
          path="/adm/log-de-integracao"
          element={
            <PageContainer protected>
              <IntegrationLog />
            </PageContainer>
          }
        />
        <Route
          path="/adm/politicas"
          element={
            <PageContainer protected>
              <PolicyPreferences />
            </PageContainer>
          }
        />
        <Route
          path="/adm/politicas/add/:companyID/:policyGroupID/policies"
          element={
            <PageContainer protected>
              <Policies />
            </PageContainer>
          }
        />
        <Route
          path="/adm/politicas/add/:companyID/:policyGroupID/policies/add"
          element={
            <PageContainer protected>
              <PoliciesForm />
            </PageContainer>
          }
        />

        <Route
          path="/adm/politicas/:companyID/:policyGroupID/users/add"
          element={
            <PageContainer protected>
              <PolicyUsers />
            </PageContainer>
          }
        />
        <Route
          path="/km/moeda"
          element={
            <PageContainer protected>
              <Currency />
            </PageContainer>
          }
        />
        <Route
          path="/km/tipo-de-despesa"
          element={
            <PageContainer protected>
              <ExpenseType />
            </PageContainer>
          }
        />
        <Route
          path="/km/fluxo-de-aprovacao"
          element={
            <PageContainer protected>
              <ApprovalFlow />
            </PageContainer>
          }
        />
        <Route
          path="/km/fluxo-de-aprovacao/add"
          element={
            <PageContainer protected>
              <ApprovalFlowForm />
            </PageContainer>
          }
        />
        <Route
          path="/km/fluxo-de-aprovacao/edit/:id"
          element={
            <PageContainer protected>
              <ApprovalFlowForm />
            </PageContainer>
          }
        />
        <Route
          path="/km/usuatios-ativos"
          element={
            <PageContainer protected>
              <ActiveUsersKm />
            </PageContainer>
          }
        />
        <Route
          path="/km/parametros-empresa"
          element={
            <PageContainer protected>
              <CompanyParameters />
            </PageContainer>
          }
        />
        <Route
          path="/rh/pendente-de-prestacoes-de-contas"
          element={
            <PageContainer protected>
              <PendingAccountability />
            </PageContainer>
          }
        />
        <Route
          path="/Dashboard/Driver/Volume"
          element={
            <PageContainerDashboard protected>
              <Volume />
            </PageContainerDashboard>
          }
        />
        <Route
          path="/Dashboard/Driver/Gastos"
          element={
            <PageContainerDashboard protected>
              <Gastos />
            </PageContainerDashboard>
          }
        />
        <Route
          path="/expen/adiantamentos"
          element={
            <PageContainer protected>
              <Advances />
            </PageContainer>
          }
        />
        <Route
          path="/expen/relatorios"
          element={
            <PageContainer protected>
              <Reports />
            </PageContainer>
          }
        />
        <Route
          path="/expen/despesas"
          element={
            <PageContainer protected>
              <Expenses />
            </PageContainer>
          }
        />
        <Route
          path="/expen/despesas/add"
          element={
            <PageContainer protected>
              <ExpensesForm />
            </PageContainer>
          }
        />
        <Route
          path="/expen/despesas/edit/:expenseID"
          element={
            <PageContainer protected>
              <ExpensesForm />
            </PageContainer>
          }
        />

        <Route
          path="/expen/despesas/add/km-manual"
          element={
            <PageContainer protected>
              <ExpensesKmForm />
            </PageContainer>
          }
        />
        <Route
          path="/expen/despesas/add/km-interno"
          element={
            <PageContainer protected>
              <ExpensesKmForm />
            </PageContainer>
          }
        />
        <Route
          path="/expen/despesas/edit/km-interno/:expenseID"
          element={
            <PageContainer protected>
              <ExpensesKmForm />
            </PageContainer>
          }
        />
        <Route
          path="/expen/despesas/edit/km-manual/:expenseID"
          element={
            <PageContainer protected>
              <ExpensesKmForm />
            </PageContainer>
          }
        />
        <Route
          path="/expen/relatorios-despesas"
          element={
            <PageContainer protected>
              <ExpenseReport />
            </PageContainer>
          }
        />
        <Route
          path="/expen/relatorios-km"
          element={
            <PageContainer protected>
              <KmReport />
            </PageContainer>
          }
        />
        <Route
          path="/expen/aprovacao"
          element={
            <PageContainer protected>
              <Approval />
            </PageContainer>
          }
        />
        <Route
          path="/expen/adm-financeira"
          element={
            <PageContainer protected>
              <FinancialManagement />
            </PageContainer>
          }
        />
        <Route
          path="/evaluate/:reportID"
          element={
            <PageContainer protected>
              <Evaluate />
            </PageContainer>
          }
        />
        <Route
          path="/driver/conciliar-corrida/:receiptID"
          element={
            <PageContainer protected>
              <ConciliateRace />
            </PageContainer>
          }
        />
      </Routes>

      {openIdleTimeWarningSnackbar && (
        <Snackbar open={true}>
          <Alert severity="warning" sx={{ width: '100%' }}>
            Alerta de inatividade ! Você sera redirecionado para a página de
            login em {idleTimer} segundos.
          </Alert>
        </Snackbar>
      )}
    </MsalProvider>
  );
}

export default App;
