import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ResponsiveAppBar from '../components/NavBar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import incentive from '../assets/images/incentive.png';
import creditCard from '../assets/images/credit-card.png';
import transfer from '../assets/images/transfer.png';

import Footer from '../components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { GetUser, getUserData } from '../store/_Entities/User';
import { Box } from '@mui/material';
import logo from '../assets/images/logow.png';
import { GetCompaniesByUserID } from '../store/_Entities/Company';

const Home = () => {
  const { user } = useSelector((state) => state.entities);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetCompaniesByUserID(user.authData.sub));
    dispatch(GetUser(user.authData.sub));
  }, []);

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth="md">
        <Box
          container
          spacing={2}
          className="home-comp"
          sx={{ marginTop: '45px', textAlign: 'center' }}
        >
          <Typography
            variant="h2"
            component="div"
            sx={{
              color: '#686868',
              textAlign: 'center',
              fontWeight: 'regular',
            }}
          >
            Bem vindo a
          </Typography>
          <img src={logo} alt="" style={{ width: '424px' }} />
        </Box>
      </Container>
    </>
  );
};

export default Home;
