import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import HeaderSelect from '../../../components/HeaderSelect';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GroupIcon from '@mui/icons-material/Group';
import BlockIcon from '@mui/icons-material/Block';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetCostCenters } from '../../../store/_Entities/CostCenter';
import { ReportProblem } from '@mui/icons-material';
import { ModalWrapper } from '../../../components/Modal';
import CustomDataGrid from '../../../components/grids/components/CustomDataGrid';
import HeaderSearchSelectCompany from '../../../components/HeaderSearchSelectCompany';
import { setLastCompanyFiltered } from '../../../store/_Entities/Company';

const ApprovalFlow = () => {
  const { company, costCenter } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [period, setPeriod] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(() => {
    return company.lastCompanyFiltered || '';
  });
  const [costCenters, setCostCenters] = useState([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState(null);
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [selectedApprovalFlow, setSelectedApprovalFlow] = useState(null);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(GetCostCenters(selectedCompany));
      dispatch(setLastCompanyFiltered(selectedCompany));
    } else {
      setCostCenters([]);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany && costCenter.costCenters) {
      setCostCenters(costCenter.costCenters);
    }
  }, [costCenter.costCenters]);

  const data = [
    {
      alert: true,
      codigo: '001',
      descricao: 'Fluxo A',
    },
    {
      alert: false,
      codigo: '002',
      descricao: 'Fluxo B',
    },
  ];

  const dataUsers = [
    {
      approvalFlowUserID: 1,
      name: 'Usuário 1',
      costCenter: 'Centro de Custo 1',
    },
    {
      approvalFlowUserID: 2,
      name: 'Usuário 2',
      costCenter: 'Centro de Custo 2',
    },
    {
      approvalFlowUserID: 3,
      name: 'Usuário 3',
      costCenter: 'Centro de Custo 3',
    },
    {
      approvalFlowUserID: 4,
      name: 'Usuário 4',
      costCenter: 'Centro de Custo 4',
    },
  ];

  const columns = [
    {
      field: 'alert',
      headerName: 'Alerta',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          params.row.alert && (
            <Tooltip title="Alerta" placement="top">
              <ReportProblem />
            </Tooltip>
          )
        );
      },
    },
    {
      field: 'codigo',
      headerName: 'Código',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'usuariosVinculados',
      headerName: 'Usuários Vinculados',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          color="primary"
          onClick={() => {
            setSelectedApprovalFlow(params.row.codigo);
            setShowUsersModal(true);
          }}
        >
          <GroupIcon />
        </IconButton>
      ),
    },
    {
      field: 'editar',
      headerName: 'Editar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          color="primary"
          onClick={() => navigate(`edit/${params.row.codigo}`)}
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'deletar',
      headerName: 'Deletar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: () => (
        <IconButton color="secondary">
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const columnsUsers = [
    {
      field: 'approvalFlowUserID',
      headerName: 'ID',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
    },
    {
      field: 'name',
      headerName: 'Nome',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
    },
    {
      field: 'actions',
      headerName: 'Deletar',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <IconButton
          color="secondary"
          onClick={() => console.log('Deletar', params.row.approvalFlowUserID)}
        >
          <DeleteIcon />
        </IconButton>
      ),
      minWidth: 100,
    },
  ];

  const exportHeaders = {
    codigo: 'Código',
    descricao: 'Descrição',
    usuariosVinculados: 'Usuários Vinculados',
    editar: 'Editar',
    deletar: 'Deletar',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                marginBottom: '10px',
                borderBottom: '1px solid black',
                paddingBottom: 2,
              }}
            >
              <h2>Fluxo de Aprovação</h2>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  flexWrap: 'wrap',
                }}
              >
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  <Box display={'flex'}>
                    <HeaderSearchSelectCompany
                      label="Empresas"
                      onChange={setSelectedCompany}
                      defaultValue={selectedCompany}
                    />
                    <HeaderSelect
                      label="Centro de Custo"
                      onChange={(event) => {
                        setSelectedCostCenter(event.target.value);
                      }}
                      menuItems={costCenters.map((costCenter) => {
                        return {
                          label: costCenter.description,
                          value: costCenter.costCenterID,
                        };
                      })}
                      defaultValue={undefined}
                    />
                    <HeaderSelect
                      label="Usuários"
                      onChange={(event) => {
                        setPeriod(event.target.value);
                      }}
                      menuItems={[
                        { label: 'usuário 1', value: 1 },
                        { label: 'usuário 2', value: 2 },
                        { label: 'usuário 3', value: 3 },
                        { label: 'usuário 4', value: 4 },
                      ]}
                      defaultValue={undefined}
                    />
                    <HeaderSelect
                      label="Tipo"
                      onChange={(event) => {
                        setPeriod(event.target.value);
                      }}
                      menuItems={[
                        { label: 'usuário 1', value: 1 },
                        { label: 'usuário 2', value: 2 },
                        { label: 'usuário 3', value: 3 },
                        { label: 'usuário 4', value: 4 },
                      ]}
                      defaultValue={undefined}
                    />
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection="column"
                  gap={2}
                  sx={{ mt: { xs: 2, md: 0 } }}
                >
                  <Button
                    variant="contained"
                    onClick={() => console.log('Download modelo')}
                  >
                    <DownloadIcon />
                    Download Arquivo
                  </Button>
                  <Button variant="contained" href="/km/fluxo-de-aprovacao/add">
                    <AddIcon />
                    Adicionar
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => console.log('Bloquear Envio de Relatório')}
                  >
                    <BlockIcon />
                    Bloquear Envio de Relatório
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.codigo}
          exportHeaders={exportHeaders}
        />
        <ModalWrapper
          title={'Usuários Vinculados'}
          showModal={showUsersModal}
          closeModalAction={() => setShowUsersModal(false)}
          maxWidth={800}
        >
          <Box
            display={'flex'}
            justifyContent={{ xs: 'center', md: 'space-evenly' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            gap={4}
          >
            <HeaderSelect
              label="Centro de custo"
              onChange={(event) => {
                setPeriod(event.target.value);
              }}
              menuItems={[
                { label: 'Centro de Custo 1', value: 1 },
                { label: 'Centro de Custo 2', value: 2 },
                { label: 'Centro de Custo 3', value: 3 },
                { label: 'Centro de Custo 4', value: 4 },
              ]}
              defaultValue={undefined}
            />
            <HeaderSelect
              label="Usuários"
              onChange={(event) => {
                setPeriod(event.target.value);
              }}
              menuItems={[
                { label: 'usuário 1', value: 1 },
                { label: 'usuário 2', value: 2 },
                { label: 'usuário 3', value: 3 },
                { label: 'usuário 4', value: 4 },
              ]}
              defaultValue={undefined}
            />
            <Button variant="contained">
              <AddIcon />
              Adicionar
            </Button>
          </Box>
          <CustomDataGrid
            rows={dataUsers}
            columns={columnsUsers}
            props={{
              getRowId: (row) => row.approvalFlowUserID,
              disableSelectionOnClick: true,
            }}
            width={600}
          />
        </ModalWrapper>
      </Container>
    </>
  );
};

export default ApprovalFlow;
