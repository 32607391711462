import { Box, Checkbox, Container, FormControlLabel, Grid, Typography } from "@mui/material";
import { useState } from "react";
import ResponsiveAppBar from '../../../components/NavBar';

const CompanyParameters = () => {
  const [checked, setChecked] = useState({ daily: true, event: false });

  const handleChange = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };
  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black'
            }}
          >
            <h2>Parâmetros Empresa</h2>
          </Grid>
        </Grid>
        <Box sx={{ border: '1px solid #ddd', padding: 2, borderRadius: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked.daily}
                onChange={handleChange}
                name="daily"
                color="primary"
              />
            }
            label={
              <Box>
                <Typography variant="body1" component="span" sx={{ fontWeight: 'bold' }}>
                  Diário:
                </Typography>
                <Typography variant="body2" component="span" sx={{ marginLeft: 1 }}>
                  E-mail enviado diariamente (07h00) informando a existência de relatórios pendentes para aprovação.
                </Typography>
              </Box>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked.event}
                onChange={handleChange}
                name="event"
                color="primary"
              />
            }
            label={
              <Box>
                <Typography variant="body1" component="span" sx={{ fontWeight: 'bold' }}>
                  Por evento:
                </Typography>
                <Typography variant="body2" component="span" sx={{ marginLeft: 1 }}>
                  E-mail enviado a cada evento, informando a existência de um relatório para aprovar.
                </Typography>
              </Box>
            }
          />
        </Box>
      </Container>
    </>
  )
}

export default CompanyParameters;
