import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Container,
  Grid,
  Typography,
  MenuItem,
  Button,
  Select,
  IconButton,
  Box,
  Stepper,
  Step,
  StepLabel,
  Divider,
} from '@mui/material';
import ResponsiveAppBar from '../../NavBar';

import CustomDataGrid from '../../grids/components/CustomDataGrid';
import FormInput from '../../FormInput';
import { useNavigate, useParams } from 'react-router-dom';
import { Add, Delete } from '@mui/icons-material';
import HeaderSelect from '../../HeaderSelect';
import Footer from '../../Footer';
import ModalConfirm from '../../ModalConfirm';

const mockData = [
  {
    id: 1,
    name: 'Usuário 1',
    costCenter: 'Centro de Custo 1',
    condition: 1,
  },
  {
    id: 2,
    name: 'Usuário 2',
    costCenter: 'Centro de Custo 2',
    condition: 1,
  },
  {
    id: 3,
    name: 'Usuário 3',
    costCenter: 'Centro de Custo 3',
    condition: 2,
  },
  {
    id: 4,
    name: 'Usuário 4',
    costCenter: 'Centro de Custo 4',
    condition: 1,
  },
];

const ApprovalFlowForm = () => {
  const { approvalFlowID } = useParams();
  const navigate = useNavigate();
  const [steps, setSteps] = useState(1);
  const [actualStep, setActualStep] = useState(0);
  const [data, setData] = useState(mockData);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCostCenter, setSelectedCostCenter] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      description: '',
      approvalFlowUsers: [],
    },
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  const handleAddUser = () => {
    console.log('Add User');
  };

  const CustomStepIcon = () => {
    return (
      <Box
        onClick={() => setSteps((prev) => prev + 1)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 32,
          height: 32,
          backgroundColor: 'grey.500',
          color: '#fff',
          borderRadius: '50%',
          cursor: 'pointer',
          zIndex: 10,
        }}
      >
        <Add />
      </Box>
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Usuário',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'condition',
      headerName: 'Condição',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Select
            displayEmpty
            value={params.row.condition}
            sx={{ width: '70px', height: '30px' }}
          >
            <MenuItem value={1}>E</MenuItem>
            <MenuItem value={2}>OU</MenuItem>
          </Select>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Ações',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <IconButton onClick={() => console.log('Delete Row')}>
            <Delete />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container sx={{ mt: 6 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            alignItems={'center'}
            sx={{
              boxShadow: '0px 0px 4px 0px #00000040',
              borderRadius: '10px',
              padding: 2,
              display: 'flex',
              alignItems: 'end',
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h6">Fluxo de Aprovação</Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <FormInput
                    label="Descrição"
                    field={field}
                    errors={errors?.approvalFlowUsers}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Stepper
                activeStep={actualStep}
                alternativeLabel
                sx={{
                  '& .MuiStepIcon-root': {
                    fontSize: '2rem',
                    zIndex: 1,
                  },
                }}
              >
                {Array.from({ length: steps }).map((_, index) => (
                  <Step key={index}>
                    <StepLabel
                      onClick={() => setActualStep(index)}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      {index > 0 && (
                        <IconButton onClick={() => setShowDeleteModal(true)}>
                          <Delete />
                        </IconButton>
                      )}
                    </StepLabel>
                  </Step>
                ))}
                <Step key="add-step">
                  <StepLabel StepIconComponent={CustomStepIcon}>
                    Adicionar Etapa
                  </StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                O relatório precisa ser aprovado por:
              </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <HeaderSelect
                label="Centro de Custo"
                onChange={(e) => setSelectedCostCenter(e.target.value)}
                menuItems={[
                  { value: '1', label: 'Centro de Custo 1' },
                  { value: '2', label: 'Centro de Custo 2' },
                  { value: '3', label: 'Centro de Custo 3' },
                ]}
              />
            </Grid>
            <Grid item xs={2.5}>
              <HeaderSelect
                label="Usuários"
                onChange={(e) => setSelectedUser(e.target.value)}
                menuItems={[
                  { value: '1', label: 'Usuário 1' },
                  { value: '2', label: 'Usuário 2' },
                  { value: '3', label: 'Usuário 3' },
                ]}
              />
            </Grid>
            {actualStep !== 0 && (
              <Grid item xs={2.5}>
                <HeaderSelect
                  label="Etapa obrigatória"
                  defaultValue={1}
                  menuItems={[
                    { value: 1, label: 'SIM' },
                    { value: 2, label: 'NÃO' },
                  ]}
                />
              </Grid>
            )}
            <Grid item xs={3}>
              <Button variant="contained" onClick={handleAddUser}>
                <Add />
                Adicionar
              </Button>
            </Grid>
            <Divider sx={{ width: '100%', marginTop: '6px' }} />
            <Grid item xs={12}>
              <CustomDataGrid
                columns={columns}
                rows={data}
                pageSize={5}
                props={{
                  disableSelectionOnClick: true,
                }}
              />
            </Grid>
          </Grid>

          <Box display={'flex'} gap={2} mt={2} justifyContent={'end'}>
            <Button
              variant="outlined"
              onClick={() => navigate('/km/fluxo-de-aprovacao')}
            >
              Cancelar
            </Button>
            <Button variant="contained" type="submit">
              Salvar
            </Button>
          </Box>
        </form>
        <Footer />
        <ModalConfirm
          title={'Deseja Excluir a Etapa?'}
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          submit={() => {
            setSteps((prev) => prev - 1);
            setShowDeleteModal(false);
          }}
        >
          <Typography variant="subtitle1">
            A Etapa será excluida permanentemente.
          </Typography>
        </ModalConfirm>
      </Container>
    </>
  );
};

export default ApprovalFlowForm;
