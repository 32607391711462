// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// @ts-ignore
import { apiCallBegan } from '../api';

import { IState } from './interfaces/User';
import { ILoginDto } from './interfaces/User';
import { Endpoints } from '../../constants/endpoints';
import { IApi, IRequestResponse } from '../middleware/interfaces/api';
import parseJwt from '../../utils/parseJwt';
import { IAccountHolder } from './interfaces/AccountHolder';
import getAttributeContains from '../../utils/getAttributeContains';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  isLoggedIn: false,
  loginDate: new Date(),
  access_token: null,
  validCaptcha: false,
  users: [],
  user: {
    userID: '',
    companyID: 0,
    company: '',
    costCenterID: 0,
    costCenter: '',
    number: '',
    name: '',
    registration: '',
    email: '',
    phone: '',
    status: 0,
    updated: '',
    origin: '',
    originID: '',
    blocked: false,
    blockedCompany: false,
    blockedCostCenter: false,
    mobGov: false,
    profile: '',
  },
  selectedUser: {
    userID: '',
    companyID: 0,
    company: '',
    costCenterID: 0,
    costCenter: '',
    number: '',
    name: '',
    registration: '',
    email: '',
    phone: '',
    status: 0,
    updated: '',
    origin: '',
    originID: '',
    blocked: false,
    blockedCompany: false,
    blockedCostCenter: false,
    mobGov: false,
    profile: '',
  },
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    USER_REQUESTED: (user: IState, action: PayloadAction<IState>) => {
      user.loading = true;
      user.error = false;
      user.success = false;
    },

    USER_FAILED: (user: IState, action: PayloadAction<IState>) => {
      user.loading = false;
      user.error = true;
      user.success = false;
      user.validCaptcha = false;
    },

    USER_LOGGED_IN_SUCCESSFULLY: (user: IState, action: PayloadAction<any>) => {
      const { access_token, refresh_token } = action.payload;

      user.loading = false;
      user.error = false;
      user.success = true;
      user.isLoggedIn = true;
      user.access_token = access_token;
      const authData = parseJwt(access_token);
      user.authData = authData;
      user.loginDate = new Date();
      console.log(authData);

      // http://schemas.microsoft.com/ws/2008/06/identity/claims/role
      const roles = getAttributeContains(authData, 'claims/role');
      user.roles = roles;

      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);
      user.validCaptcha = false;
    },
    USER_LOGOUT: (user: IState, action: PayloadAction<IState>) => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      user.isLoggedIn = false;
      user.access_token = null;
      user.authData = null;
      user.data = undefined;
      user.loading = false;
    },
    REFRESH_TOKEN: (user: IState, action: PayloadAction<any>) => {
      const { access_token, refresh_token } = action.payload;
      user.access_token = access_token;
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);
      user.authData = parseJwt(access_token);
    },
    REFRESH_REQUESTED: (user: IState, action: PayloadAction<IState>) => {
      console.log('refresh requested');
    },
    GET_USERS_SUCCESS: (user, action) => {
      user.loading = false;
      user.error = false;
      user.success = true;
      user.users = action.payload.result;
    },
    GET_USER_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.user = action.payload.result;
    },
    GET_SELECTED_USER_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.selectedUser = action.payload.result;
    },
    ACTIVATE_USER_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.users = state.users.filter(
        (user) => user.userID !== action.payload.userID
      );
    },
    UPDATE_USER_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.user = {
        ...action.payload.result,
        costCenter: state.user?.costCenter,
      };
    },
  },
});

export const {
  USER_REQUESTED,
  USER_FAILED,
  USER_LOGGED_IN_SUCCESSFULLY,
  USER_LOGOUT,
  REFRESH_TOKEN,
  REFRESH_REQUESTED,
  GET_USERS_SUCCESS,
  GET_USER_SUCCESS,
  GET_SELECTED_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  ACTIVATE_USER_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const loginUser = (LoginDto: ILoginDto) => {
  const formData = new FormData();

  formData.append('grant_type', 'password');
  formData.append('client_id', 'portalv2');
  formData.append('client_secret', 'secret');
  formData.append('username', LoginDto.email);
  formData.append('password', LoginDto.password);
  formData.append('scope', 'wExpoPublicAPI wExpoExpensesPublicAPI');

  const request: IApi = {
    axiosConfig: {
      url: Endpoints.Auth.ConnectToken,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-encoded',
      },
      data: formData,
    },
    onAction: {
      onStart: USER_REQUESTED.type,
      onSuccess: USER_LOGGED_IN_SUCCESSFULLY.type,
      onError: USER_FAILED.type,
    },
  };
  return apiCallBegan(request);
};

export const loginUserSso = (email: string, providerKey: string) => {
  const formData = new FormData();

  formData.append('grant_type', 'email-token');
  formData.append('client_id', 'portalv2');
  formData.append('client_secret', 'secret');
  formData.append('email', email);
  formData.append(
    'scope',
    'wExpoPublicAPI wExpoExpensesPublicAPI offline_access'
  );
  formData.append('providerKey', providerKey);

  const request: IApi = {
    axiosConfig: {
      url: Endpoints.Auth.ConnectToken,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-encoded',
      },
      data: formData,
    },
    onAction: {
      onStart: USER_REQUESTED.type,
      onSuccess: USER_LOGGED_IN_SUCCESSFULLY.type,
      onError: USER_FAILED.type,
    },
  };
  return apiCallBegan(request);
};

export const refreshToken = () => {
  const formData = new FormData();

  const refreshToken = localStorage.getItem('refresh_token');
  if (!refreshToken) return;

  formData.append('grant_type', 'refresh_token');
  formData.append('client_id', 'portalv2');
  formData.append('client_secret', 'secret');
  formData.append('refresh_token', refreshToken);
  formData.append('scope', 'wExpoPublicAPI wExpoExpensesPublicAPI');

  const request: IApi = {
    axiosConfig: {
      url: Endpoints.Auth.ConnectToken,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-encoded',
      },
      data: formData,
    },
    onAction: {
      onStart: REFRESH_REQUESTED.type,
      onSuccess: REFRESH_TOKEN.type,
      onError: USER_FAILED.type,
    },
  };
  return apiCallBegan(request);
};

export const GetUsersByCostCenter = (url: string) => {
  return apiCallBegan({
    axiosConfig: {
      url: Endpoints.User.GetUser + url,
      method: 'get',
    },
    onAction: {
      onStart: USER_REQUESTED.type,
      onSuccess: GET_USERS_SUCCESS.type,
      onError: USER_FAILED.type,
    },
  });
};

export const GetActiveUsers = (companyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      url: Endpoints.User.GetActiveUsers(companyID),
      method: 'get',
    },
    onAction: {
      onStart: USER_REQUESTED.type,
      onSuccess: GET_USERS_SUCCESS.type,
      onError: USER_FAILED.type,
    },
  });
};

export const GetInactiveUsers = (companyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      url: Endpoints.User.GetInactiveUsers(companyID),
      method: 'get',
    },
    onAction: {
      onStart: USER_REQUESTED.type,
      onSuccess: GET_USERS_SUCCESS.type,
      onError: USER_FAILED.type,
    },
  });
};

export const GetLoggedUser = (userID: string) => {
  return apiCallBegan({
    axiosConfig: {
      url: Endpoints.User.GetUser + '/' + userID,
      method: 'get',
    },
    onAction: {
      onStart: USER_REQUESTED.type,
      onSuccess: GET_USER_SUCCESS.type,
      onError: USER_FAILED.type,
    },
  });
};

export const GetUser = (userID: string, selectedUser: boolean) => {
  return apiCallBegan({
    axiosConfig: {
      url: Endpoints.User.GetUser + '/' + userID,
      method: 'get',
    },
    onAction: {
      onStart: USER_REQUESTED.type,
      onSuccess: selectedUser
        ? GET_SELECTED_USER_SUCCESS.type
        : GET_USER_SUCCESS.type,
      onError: USER_FAILED.type,
    },
  });
};

export const UpdateSelectedUser = (userID: string, data: any) => {
  return apiCallBegan({
    axiosConfig: {
      url: Endpoints.User.GetUser + '/' + userID,
      method: 'PUT',
      data,
    },
    onAction: {
      onStart: USER_REQUESTED.type,
      onSuccess: GET_SELECTED_USER_SUCCESS.type,
      onError: USER_FAILED.type,
      showToast: true,
    },
  });
};

export const ActivateUser = (userID: string, originUserID: string) => {
  return apiCallBegan({
    axiosConfig: {
      url: Endpoints.User.ActivateUser(userID, originUserID),
      method: 'POST',
    },
    onAction: {
      onStart: USER_REQUESTED.type,
      onSuccess: ACTIVATE_USER_SUCCESS.type,
      onError: USER_FAILED.type,
      showToast: true,
    },
  });
};

export const UpdateUser = (userID: string, data: any) => {
  return apiCallBegan({
    axiosConfig: {
      url: Endpoints.User.UpdateUser(userID),
      method: 'PUT',
      data,
    },
    onAction: {
      onStart: USER_REQUESTED.type,
      onSuccess: UPDATE_USER_SUCCESS.type,
      onError: USER_FAILED.type,
      showToast: true,
    },
  });
};
