import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
  Typography,
  Box,
} from '@mui/material';

const HeaderSelect = ({ label, defaultValue, onChange, menuItems, error }) => {
  return (
    <FormControl
      variant="standard"
      sx={{ width: { xs: '100%', sm: 150, md: 200 } }}
    >
      <Box>
        {error && (
          <Typography variant="caption" color="error">
            Campo obrigatório
          </Typography>
        )}
      </Box>
      <InputLabel id="company-select-label">{label}</InputLabel>
      <Select
        labelId="company-select-label"
        id="company-simple-select"
        displayEmpty
        disableUnderline
        defaultValue={defaultValue || ''}
        error={error}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
        onChange={onChange}
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label.length > 20 ? (
              <Tooltip title={item.label} placement="right">
                <span>{item.label.substring(0, 20) + '...'}</span>
              </Tooltip>
            ) : (
              item.label
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default HeaderSelect;
